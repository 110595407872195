import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchMyBets } from "../../api/_front/mybetsApi";

const mybetsSlice = createSlice({
  name: "mybets",
  initialState: {
    mybets: [],
    history: [],
    status: "idle",
    error: null,
  },
  reducers: {
    updateMyBets: (state, action) => {
      state.mybets = action.payload;
    },
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { updateMyBets, setHistory, setStatus, setError } =
  mybetsSlice.actions;

// export const handleMyBets = (tab, token) => async (dispatch, getState) => {
//     try {
//         // Set the status to loading before starting the request
//         dispatch(setStatus('loading'));

//         // Fetch data from backend
//         const getMyBets = await fetchMyBets(tab, token);

//         // If the request is successful, update the mybets state and set status to 'succeeded'
//         dispatch(updateMyBets(getMyBets));
//         dispatch(setStatus('succeeded'));
//     } catch (error) {
//         // If the request fails, set status to 'failed' and set the error message
//         dispatch(setStatus('failed'));
//         dispatch(setError(error.toString()));
//     }
// };

export default mybetsSlice.reducer;
