import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  oddType: 'decimal'
};

const oddTypeSlice = createSlice({
  name: 'oddType',
  initialState,
  reducers: {
    setOddType: (state, action) => {
      state.oddType = action.payload;
    },
  }
});

export const { setOddType } = oddTypeSlice.actions;

export default oddTypeSlice.reducer;