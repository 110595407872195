import { createSlice } from '@reduxjs/toolkit';

const pageSlice = createSlice({
  name: 'page',
  initialState: {
    pageType: 'default'
  },
  reducers: {
    setPageType: (state, action) => {
      state.pageType = action.payload;
    }
  }
});

export const { setPageType } = pageSlice.actions;

export default pageSlice.reducer;