import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: "theme",
  initialState: "dark",
  reducers: {
    toggleTheme(state) {
      ////////////console.log("startsssss", state);
      const newState = state === "dark" ? "light" : "dark";
      return newState;
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
