import { createSlice } from "@reduxjs/toolkit";
import {
  getAdminUser,
  getRegistrationData,
} from "../../../api/_admin/adminUserApi";

//reset state helper function
const resetState = (state) => {
  state.isLoading = true;
  state.responseType = "idle";
  state.response = null;
};

//error or fail helper function
const handleFail = (state, action) => {
  state.responseType = "error";
  state.response = action.payload;
  state.isLoading = false;
};

//create slice
export const adminUserSlice = createSlice({
  name: "adminUser",
  initialState: {
    list: [],
    total: 0,
    perPage: 0,
    currentPage: 1,
    status: "idle",
    error: null,
    userRegistrations: [],
  },
  reducers: {
    //for ADMIN USERS
    fetchAdminUserStart: resetState,
    fetchAdminUserFail: handleFail,

    fetchAdminUserSuccess: (state, action) => {
      state.status = "succeeded";
      state.list = action.payload.data;
      state.total = action.payload.total;
      state.perPage = action.payload.perPage;
      state.currentPage = action.payload.currentPage;
    },
    //for chart data
    fetchRegistrationDataStart: resetState,
    fetchRegistrationDataSuccess: (state, action) => {
      state.status = "success";
      state.userRegistrations = action.payload;
    },
    fetchRegistrationDataFail: handleFail,

    //clear messages
    clearMessages: resetState,
  },
});

//export actions outside
export const { clearMessages } = adminUserSlice.actions;

//export actions inside
const {
  fetchAdminUserStart,
  fetchAdminUserSuccess,
  fetchAdminUserFail,
  fetchRegistrationDataStart,
  fetchRegistrationDataSuccess,
  fetchRegistrationDataFail,
} = adminUserSlice.actions;

export const fetchAdminUsers = (data) => async (dispatch) => {
  dispatch(fetchAdminUserStart(data));
  //////////////console.log('sendingggggggggggggggggggggggggggg', data);
  try {
    const response = await getAdminUser(data);
    dispatch(fetchAdminUserSuccess(response));
  } catch (error) {
    dispatch(fetchAdminUserFail(error.message));
  }
};

//fetch registration data for chart
export const fetchRegistrationData = (data) => async (dispatch) => {
  dispatch(fetchRegistrationDataStart(data));
  try {
    const response = await getRegistrationData(data);
    //////////////console.log('am i geting sometinnnnnnnnnnnnnnnnnnnnnnnnnn', response);
    dispatch(fetchRegistrationDataSuccess(response));
  } catch (error) {
    dispatch(fetchRegistrationDataFail(error.message));
  }
};

export default adminUserSlice.reducer;
