import axios from "axios";

export const GET = (baseUrl, endpoint, data) => {
  return axios.get(`${baseUrl}${endpoint}`, { params: data });
};

export const AUTH_GET = (baseUrl, endpoint, data, token) => {
  return axios.get(`${baseUrl}${endpoint}`, {
    params: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// export const POST = (baseUrl, endpoint, data) => {
//   return axios.post(`${baseUrl}${endpoint}`, data);
// };
export const POST = (baseUrl, endpoint, data, token) => {
  return axios.post(`${baseUrl}${endpoint}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const PUT = (baseUrl, endpoint, data) => {
  return axios.put(`${baseUrl}${endpoint}`, data);
};

export const DELETE = (baseUrl, endpoint) => {
  return axios.delete(`${baseUrl}${endpoint}`);
};

export const PATCH = (baseUrl, endpoint, data) => {
  return axios.patch(`${baseUrl}${endpoint}`, data);
};

export const HEAD = (baseUrl, endpoint, params) => {
  return axios.head(`${baseUrl}${endpoint}`, { params });
};

export const OPTIONS = (baseUrl, endpoint, params) => {
  return axios.options(`${baseUrl}${endpoint}`, { params });
};
