import { BASE_URL } from "../constants";
import { POST, GET, AUTH_GET } from "../index";

export const fetchUserForMessages = async (data, token) => {
  const ENDPOINT = `/api/admin/misc-actions`;
  return handlePOST(ENDPOINT, data, token);
};

export const uploadBannersGallery = async (data, token) => {
  const ENDPOINT = `/api/admin/banners-gallery`;
  return handlePOST(ENDPOINT, data, token);
};

export const fetchGalleryData = async (data, token) => {
  const ENDPOINT = `/api/admin/misc-actions`;
  return handlePOST(ENDPOINT, data, token);
};

export const deleteGalleryImage = async (data, token) => {
  // ////////////console.log(data);
  const ENDPOINT = `/api/admin/misc-actions`;
  return handlePOST(ENDPOINT, data, token);
};

export const fetchCommissionStats = async (data, token) => {
  // ////////////console.log(data);
  const ENDPOINT = `/api/admin/misc-actions`;
  return handlePOST(ENDPOINT, data, token);
};

export const fetchAdminPartnerHistory = async (data, token) => {
  const ENDPOINT = `/api/admin/partner-history`;
  return handleGET(ENDPOINT, data, token);
};
export const getPartnerRecentTxn = async (data, token) => {
  const ENDPOINT = `/api/admin/partner-recent-txn`;
  return handleGET(ENDPOINT, data, token);
};

export const submitPartnerWithdrawal = async (data, token) => {
  const ENDPOINT = `/api/admin/partner-withdrawal`;
  return handlePOST(ENDPOINT, data, token);
};

//for

export const transferBalance = async (data, token) => {
  const ENDPOINT = `/api/admin/transfer-balance`;
  return handlePOST(ENDPOINT, data, token);
};

export const fetchScore = async (data, token) => {
  const ENDPOINT = `/api/admin/event-score`;
  return handleGET(ENDPOINT, data, token);
};

// Generic handler for GET requests
const handleGET = async (endpoint, data, token) => {
  try {
    const response = await AUTH_GET(BASE_URL, endpoint, data, token);
    return response.data.results.response;
  } catch (error) {
    console.error(error);
    let errorMessage = "Something went wrong.";
    if (error.response) {
      errorMessage = `Error: ${error.response.status} - ${errorMessage}`;
    } else if (error.request) {
      errorMessage = "Server did not respond.";
    }

    throw new Error(errorMessage);
  }
};

// Generic handler for POST requests
const handlePOST = async (endpoint, data, token) => {
  try {
    const response = await POST(BASE_URL, endpoint, data, token);
    return response.data.results.response;
  } catch (error) {
    ////////////console.log("errr", error);
    let errorMessage = "Something went wrong.";
    if (error.response) {
      errorMessage = `Error: ${error.response.status} - ${errorMessage}`;
    } else if (error.request) {
      errorMessage = "Server did not respond.";
    }

    throw new Error(errorMessage);
  }
};
