import { createSlice } from "@reduxjs/toolkit";

export const marketsSlice = createSlice({
  name: "markets",
  initialState: {
    data: {}, // Ensure this matches expected structure everywhere it's accessed
  },
  reducers: {
    setMarketsData: (state, action) => {
      if (action.payload) {
        // Additional check if necessary
        state.data = action.payload;
      }
    },
  },
});

export const { setMarketsData } = marketsSlice.actions;
export const selectMarketsData = (state) => state.markets.data;
export default marketsSlice.reducer;
