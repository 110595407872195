import { BASE_URL } from "../constants";
import { POST, GET, AUTH_GET } from "../index";

export const fetchRmgt = async (data, token) => {
    //////////////console.log('from sportsAPI', token);
    const ENDPOINT = `/api/front/risk-management`;
    return handleGET(ENDPOINT, data, token);
  };


// Generic handler for GET requests
const handleGET = async (endpoint, data, token) => {
  try {
    const response = await AUTH_GET(BASE_URL, endpoint, data, token);
    return response.data.results.response;
  } catch (error) {
    let errorMessage = "Something went wrong.";
    if (error.response) {
      errorMessage = `Error: ${error.response.status} - ${errorMessage}`;
    } else if (error.request) {
      errorMessage = "Server did not respond.";
    }

    throw new Error(errorMessage);
  }
};