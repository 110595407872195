import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bets: [],
  updateBet: true,
  updatedGame: true,
  betType: "multi",
  totalStake: 0,
  singleTotalReturn: 0,
  multiTotalReturn: 0,
  systemTotalReturn: 0,
  errorMessage: null,
  errorValue: null,
  useBonus: false,
  triggerBetslip: null,
};

const betslipSlice = createSlice({
  name: "betslip",
  initialState,
  reducers: {
    setUpdateBet: (state, action) => {
      state.updateBet = action.payload;
    },
    setBetType: (state, action) => {
      state.betType = action.payload;
    },
    setTotalStake: (state, action) => {
      state.totalStake = Number(action.payload);
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setErrorValue: (state, action) => {
      state.errorValue = action.payload;
    },
    setUseBonus: (state, action) => {
      state.useBonus = action.payload;
    },
    putBet: (state, action) => {
      state.bets = [action.payload, ...state.bets];
    },
    replaceBet: (state, action) => {
      state.bets = state.bets.map((bet, index) =>
        index === action.payload.oldBetIndex ? action.payload.newBet : bet
      );
    },
    updateBetOdds: (state, action) => {
      // Find the bet using gameId and optionId
      const bet = state.bets.find(
        (bet) =>
          bet.gameId === action.payload.gameId &&
          bet.optionId === action.payload.optionId
      );
      if (bet) bet.odds = action.payload.odds;
    },
    setTriggerBetslip: (state, action) => {
      ////////////console.log(action.payload);
      state.triggerBetslip = action.payload;
    },
    updateBetStake: (state, action) => {
      //////////////console.log('hello from betslip', action.payload);
      const bet = state.bets.find((bet) => bet._id === action.payload.betId);
      if (bet) bet.stake = Number(action.payload.value);
    },
    updateAllBetsStake: (state, action) => {
      state.bets = state.bets.map((bet) => ({
        ...bet,
        stake: Number(action.payload),
      }));
    },
    deleteBet: (state, action) => {
      // If there's only one bet, remove it and empty the array
      if (state.bets.length === 1) {
        state.bets = [];
      } else {
        // Otherwise, remove the specified bet
        state.bets = state.bets.filter((bet) => bet._id !== action.payload);
      }

      // If the new length of the bets array is less than 3, change betType to 'single'
      if (state.bets.length < 3) {
        state.betType = "single";
      }
    },
    //calculation of betslip odds
    calculateTotalReturn: (state) => {
      if (state.betType == "single") {
        let total = 0;
        state.bets.forEach((bet) => {
          if (bet.odds != "suspended" && bet.stake > 0) {
            total += bet.odds * bet.stake;
          }
        });

        state.singleTotalReturn = total;
      } else if (state.betType == "multi") {
        let totalOdds = 1;
        let totalReturn = 0;
        state.bets.forEach((bet) => {
          if (bet.odds != "suspended") {
            totalOdds *= parseFloat(bet.odds);
          }
        });

        if (state.totalStake > 0) {
          totalReturn = parseFloat(totalOdds) * parseFloat(state.totalStake);
        } else {
          totalReturn = 0;
        }

        state.multiTotalReturn = totalReturn;
      } else if (state.betType == "system") {
        let totalReturn = 0;

        if (
          state.bets[0].odds != "suspended" &&
          state.bets[1].odds != "suspended" &&
          state.bets[2].odds != "suspended" &&
          state.totalStake > 0
        ) {
          let one = state.bets[0].odds * state.bets[1].odds;
          let two = state.bets[1].odds * state.bets[2].odds;
          let three = state.bets[0].odds * state.bets[2].odds;
          let totalOdds = one + two + three;
          totalReturn = totalOdds * (state.totalStake / 3);
        }

        state.systemTotalReturn = totalReturn;
      }
    },
    clearBets: (state) => {
      state.bets = [];
      state.betType = "single";
      state.totalStake = 0;
      state.singleTotalReturn = 0;
      state.multiTotalReturn = 0;
      state.systemTotalReturn = 0;
      state.errorMessage = null;
    },
  },
});

export const {
  setUpdateBet,
  setBetType,
  setTotalStake,
  setErrorMessage,
  setErrorValue,
  putBet,
  replaceBet,
  updateBetOdds,
  updateBetStake,
  updateAllBetsStake,
  deleteBet,
  calculateTotalReturn,
  clearBets,
  setUseBonus,
  setTriggerBetslip,
} = betslipSlice.actions;

export default betslipSlice.reducer;
