import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./redux/store";

import "./i18n";

import "./index.css";
import "./media-style.css";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from "./components/ErrorBoundary";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
