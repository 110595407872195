import { createSlice } from "@reduxjs/toolkit";
import { fetchGalleryData } from "../../api/_admin/miscActionsApi";

const sliderSlice = createSlice({
  name: "slider",
  initialState: {
    slider: [],
  },
  reducers: {
    updateSlider: (state, action) => {
      //////////////console.log("from reducer", action.payload);
      state.slider = action.payload;
    },
  },
});

//internal import
export const { updateSlider } = sliderSlice.actions;

export const handleUpdateSlider = (token) => async (dispatch) => {
  try {
    const resData = await fetchGalleryData(
      { actionType: "fetchGallery" },
      token
    );
    //////////////console.log("resData", resData);
    if (resData) {
      dispatch(updateSlider(resData));
    }
  } catch (error) {
    throw error;
  }
};

export default sliderSlice.reducer;
