import { createSlice } from "@reduxjs/toolkit";
import { loginUser, updateUserApi } from "../../api/_user/userApi";

//reset state helper function
const resetState = (state) => {
  state.isLoading = true;
  state.responseType = "idle";
  state.response = null;
};

//success helper function
const successLoginRegister = (state, action) => {
  state.user = action.payload.user;
  state.token = action.payload.token;
  state.isLoading = false;
  state.responseType = "success";
  state.isLoggedIn = true;
  window.location.reload();
};

//error or fail helper function
const handleFail = (state, action) => {
  state.responseType = "error";
  state.response = action.payload;
  state.isLoading = false;
};

const initialState = {
  isLoading: false,
  responseType: "idle",
  response: null,
  isLoggedIn: false,
  user: null,
  token: null,
};
//create slice
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    //for login
    loginStart: resetState,
    loginSuccess: successLoginRegister,
    loginFail: handleFail,

    //for update
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },

    //logout
    logout: (state) => {
      // Explicitly return the initial state
      return initialState;
    },
    //clear messages
    clearMessages: resetState,
  },
});

//export actions outside
export const { clearMessages, logout } = userSlice.actions;

//export actions inside
const { loginStart, loginSuccess, loginFail, updateUser } = userSlice.actions;

export const login = (data) => async (dispatch) => {
  dispatch(loginStart());
  try {
    const response = await loginUser(data);
    if (response.status === "error") {
      dispatch(loginFail(response.message));
    } else {
      dispatch(loginSuccess(response));
    }
  } catch (error) {
    dispatch(loginFail(error.message));
  }
};

export const updateUserDetails = (data, token) => async (dispatch) => {
  try {
    const response = await updateUserApi(data, token);
    //////////////console.log("response", response);
    dispatch(updateUser(response));
  } catch (error) {
    ////////////console.log(error);
  }
};

export default userSlice.reducer;
