import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectTheme } from "../../redux/_front/globalSelectors";

function Loader() {
  const theme = useSelector(selectTheme);
  return (
    <div className={`caff_${theme} loader-container`}>
      <div className="spinner"></div>
    </div>
  );
}

export default Loader;
