import { createSlice } from "@reduxjs/toolkit";

const sportsDataSlice = createSlice({
  name: "sportsData",
  initialState: {
    sportsEvents: [],
  },
  reducers: {
    updateSportsEvents: (state, action) => {
      ////////console.log("from slice", action.payload);
      state.sportsEvents = action.payload;
    },
    resetSportsEvents: (state) => {
      state.sportsEvents = [];
    },
    appendSportsEvents: (state, action) => {
      // Assuming that action.payload and state.prematchEvents are arrays
      action.payload.forEach((newEvent) => {
        const index = state.sportsEvents.findIndex(
          (event) => event.id === newEvent.id
        );
        if (index === -1) {
          // Event doesn't exist, so append
          state.sportsEvents.push(newEvent);
        } else {
          // Event does exist, so update
          state.sportsEvents[index] = newEvent;
        }
      });
    },
    updateEventsIds: (state, action) => {
      //////////////console.log("from sportsdata slice: ", action.payload);
      action.payload.forEach((updatedEvent) => {
        const existingEvent = state.sportsEvents.find(
          (event) => event.event_id === updatedEvent.event_id
        );
        if (existingEvent) {
          existingEvent.marketsData = updatedEvent.marketsData;
          existingEvent.scores = updatedEvent.scores;
          // if (updatedEvent.sport_id === 4) {
          //   existingEvent.marketsData = updatedEvent.marketsData;
          //   //////////////console.log('Updated optionMarkets:', existingEvent.optionMarkets); // log after update
          // } else {
          //   existingEvent.marketsData = updatedEvent.;
          //   //////////////console.log('Updated Markets:', existingEvent.Markets); // log after update
          // }
        }
      });
      //////////////console.log('Updated state:', state); // log full state after update
    },
  },
});

export const {
  resetSportsEvents,
  updateSportsEvents,
  appendSportsEvents,
  updateEventsIds,
} = sportsDataSlice.actions;

export default sportsDataSlice.reducer;
