// userSelectors.js

export const selectUser = (state) => state.user.user;
export const selectToken = (state) => state.user.token;
// export const selectGames = (state) => state.game.games;
export const selectPageType = (state) => state.page.pageType;
export const selectSportsEvents = (state) => state.sportsData.sportsEvents;
// export const selectCricketPrematch = (state) => state.pregame.cricketData;
export const selectOddType = (state) => state.oddType.oddType;
export const selectTheme = (state) => state.theme;
export const selectRmgt = (state) => state.rmgt.rmgt || {};

export const selectBetsHistory = (state) => state.betsHistory;
export const selectMyBets = (state) => state.mybets.mybets || [];

export const selectStatus = (state) => state.mybets.status;
export const selectError = (state) => state.mybets.error;

//for betslip
export const selectBets = (state) => state.betslip.bets;
export const selectTriggerBetslip = (state) => state.betslip.triggerBetslip;
export const selectBetType = (state) => state.betslip.betType;
export const selectUpdateBet = (state) => state.betslip.updateBet;
export const selectTotalStake = (state) => state.betslip.totalStake;
export const selectErrorMessage = (state) => state.betslip.errorMessage;
export const selectErrorValue = (state) => state.betslip.errorValue;
export const selectSingleTotalReturn = (state) =>
  state.betslip.singleTotalReturn;
export const selectMultiTotalReturn = (state) => state.betslip.multiTotalReturn;
export const selectSystemTotalReturn = (state) =>
  state.betslip.systemTotalReturn;
export const selectUseBonus = (state) => state.betslip.useBonus;

//for slider
export const selectSlider = (state) => state.slider.slider;
