import { BASE_URL } from "../constants";
import { POST, GET, AUTH_GET } from "../index";

export const registerUser = async (data) => {
  const ENDPOINT = "/api/front/register";
  return handlePOST(ENDPOINT, data);
};

export const loginUser = async (data) => {
  const ENDPOINT = "/api/front/login";
  return handlePOST(ENDPOINT, data);
};
export const resetPassword = async (data) => {
  const ENDPOINT = "/api/front/reset-password";
  return handlePOST(ENDPOINT, data);
};

export const updateUserApi = async (data, token) => {
  const ENDPOINT = "/api/front/user";
  return handleGET(ENDPOINT, data, token);
};

// Generic handler for POST requests
const handlePOST = async (endpoint, data) => {
  try {
    const response = await POST(BASE_URL, endpoint, data);
    ////////////console.log("registration", response);
    return response.data.results.response; // Access 'response' key here
  } catch (error) {
    ////////////console.log(error.response);
    let errorMessage = "Something went wrong.";

    if (error.response) {
      errorMessage = `Error: ${error.response.status} - ${errorMessage}`;
    } else if (error.request) {
      errorMessage = "Server did not respond.";
    }

    throw new Error(errorMessage);
  }
};

// Generic handler for GET requests
const handleGET = async (endpoint, data, token) => {
  try {
    const response = await AUTH_GET(BASE_URL, endpoint, data, token);
    return response.data.results.response;
  } catch (error) {
    ////////////console.log(error.response.data.message);
    let errorMessage = "Something went wrong.";
    if (error.response) {
      errorMessage = `Error: ${error.response.status} - ${errorMessage}`;
    } else if (error.request) {
      errorMessage = "Server did not respond.";
    }

    throw new Error(errorMessage);
  }
};
